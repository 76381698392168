/* global Intercom */

// On submission of the newsletter subscription form, we pass the Intercom visitor ID.
document.addEventListener('DOMContentLoaded', function () {
  const form = document.querySelector('#js_newsletterSubscriptionForm')

  if (!form || typeof Intercom === 'undefined') return

  form.addEventListener('submit', function () {
    document.querySelector('#js__intercomUserIdInput').value = Intercom('getVisitorId')
  })
})
